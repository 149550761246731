import React, { useEffect } from 'react'
import { Flex, Text, Box } from '@chakra-ui/react'
import { PercentageSlider } from '../../ui-components/src/PercentageSlider'
import { useFormikContext } from 'formik'
import { DropdownControl } from '@mylifetime-packages/form-components'
import { CurrencyInputControl } from './CurrencyInputControl'

export enum CalcQuestionEnum {
  LIFE = 'life',
  TRAUMA = 'trauma',
  TPD = 'tpd'
}

/*
  There are two types of sliders in this file. Keep note of this when working with this file.
  - Selected sliders (clientLoans, partnerLoans) work with a selectedIndex as they are edited 1 by 1 on the front end.
  - All sliders, (dependents) are the second type. They are all edited together and require a parentIndex to keep track rather than having a selectedIndex.
  - These two types are joined together with ths type [key: string]: number | Array<{ [key: string]: number }> | undefined
  - Ideally this file would be split into these two types, but this is only in retrospect.
*/
interface FormValues {
  selectedLoan: number
  clientLoans?: Array<{
    [key: string]: number
  }>
  partnerLoans?: Array<{
    [key: string]: number
  }>
  dependents: Array<{
    traumaCapital: number
    lifeCapital: number
    tpdCapital: number
  }>
  [key: string]: number | Array<{ [key: string]: number }> | undefined
}


export function SliderCalculatorControl({
  amount,
  questions,
  formName,
  loanOwner,
  parentIndex,
  hideSliderPercentDisplay,
  name
}: {
  amount: number
  questions: { key: string; value: string }[]
  formName: string
  loanOwner?: string
  name?: string
  parentIndex?: number
  hideSliderPercentDisplay?: boolean
}) {
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const [sliderValues, setSliderValues] = React.useState<
    Record<string, number>
  >({})

  useEffect(() => {
    // Update slider values when the capital values change
    questions.forEach((question) => {
      const capital = Number(values[`${question.key}Capital`])

      if (capital) {
        const sliderValue = (capital / amount) * 100
        setSliderValues((prev) => ({ ...prev, [question.key]: sliderValue }))
      }
    })
    if (typeof(parentIndex) === 'number') {
      const traumaCapital = values.dependents[parentIndex].traumaCapital
      const lifeCapital = values.dependents[parentIndex].lifeCapital
      const tpdCapital = values.dependents[parentIndex].tpdCapital
      // Update slider values when the capital values change
      if (traumaCapital) {
        const sliderValue = (traumaCapital / amount) * 100
        setSliderValues((prev) => ({ ...prev, trauma: sliderValue }))
      }
      if (lifeCapital) {
        const sliderValue = (lifeCapital / amount) * 100
        setSliderValues((prev) => ({ ...prev, life: sliderValue }))
      }
      if (tpdCapital) {
        const sliderValue = (tpdCapital / amount) * 100
        setSliderValues((prev) => ({ ...prev, tpd: sliderValue }))
      }
    }
  }, [values.traumaCapital, values.lifeCapital, values.tpdCapital])

  useEffect(() => {
    // Update capital values based on the amount and form specifics when `amount` changes.
    questions.forEach((question) => {
      const selectedYears = Number(
        values[`${formName}${question.key}Capital${name}`]
      )
      const calculatedCapital = Math.floor(selectedYears * amount)
      setFieldValue(`${question.key}Capital${name}`, calculatedCapital)
    })
  }, [amount])

  // Resets fields when selected loan changes in debts form
  useEffect(() => {
    questions.forEach((question) => {
      let newCapitalValue = 0

      const selectedLoanIndex = values.selectedLoan
      const capitalKey = `${question.key}Capital`

      if (loanOwner === 'client') {
        newCapitalValue =
          (
            values.clientLoans?.[selectedLoanIndex]
          )?.[capitalKey] || 0
      } else if (loanOwner === 'partner') {
        newCapitalValue =
          (
            values.partnerLoans?.[selectedLoanIndex]
          )?.[capitalKey] || 0
      }

      setFieldValue(`${question.key}Capital`, newCapitalValue)
      const sliderValue = (newCapitalValue / amount) * 100
      setSliderValues((prev) => ({ ...prev, [question.key]: sliderValue }))
    })
  }, [values.selectedLoan, loanOwner])

    const dropdownOptions = [
      { key: '0', label: 'No funding' },
      { key: '0.25', label: '3 months' },
      { key: '0.5', label: '6 months' },
      { key: '1', label: '1 year' },
      { key: '2', label: '2 years' },
      { key: '3', label: '3 years' },
      { key: '4', label: '4 years' },
      { key: '5', label: '5 years' },
      { key: '6', label: '6 years' },
      { key: '7', label: '7 years' },
      { key: '8', label: '8 years' },
      { key: '9', label: '9 years' },
      { key: '10', label: '10 years' }
    ]

  // When slider changes updates the captial value
  const handleSliderChange = (value: number, questionKey: string) => {
    const adjustedValue = value > 100 ? 100 : value
    setSliderValues((prev) => ({ ...prev, [questionKey]: adjustedValue }))
    const newCurrencyValue = Math.floor((value / 100) * amount)
    if (formName === 'debts') {
      setFieldValue(`${questionKey}Capital`, newCurrencyValue)
    }
  }

  const handleDropdownChange = (event: any, questionKey: string) => {
    const selectedYear = Number(event.target.value)
    const adjustedAmount = Math.floor(amount * selectedYear)
    setFieldValue(`${questionKey}Capital${name}`, adjustedAmount)
  }

  return (
    <>
      {questions.map((question, index) => {
        const flexProps =
          formName === 'expense'
            ? { align: 'flex-start', justify: 'flex-start', gap: '5rem', }
            : { align: 'center', justify: 'space-between' }
        return (
          <Box key={index}>
            <Text mb={4}>{question.value}</Text>
            <Flex key={index} mb={4} {...flexProps}>
              {(formName === 'debts') && (
                <Box mt={8} ml={1} width='60%'>
                  <PercentageSlider
                    sliderValue={sliderValues[question.key] || 0}
                    setSliderValue={(value) =>
                      handleSliderChange(value, question.key)
                    }
                    hideSliderPercentDisplay={hideSliderPercentDisplay}
                  />
                </Box>
              )}

              {formName === 'expense' && (
                <>
                  <Box width='30%'>
                    <DropdownControl
                      name={`${formName}${question.key}Capital${name}`}
                      label=''
                      dropdownOptions={dropdownOptions}
                      useKey={true}
                      onChange={(event) =>
                        handleDropdownChange(event, question.key)
                      }
                    />
                  </Box>
                  <Box width='30%'>
                    <CurrencyInputControl
                      name={`${question.key}Capital${name}`}
                      variant='filled'
                      fontFamily={'thin'}
                      placeholder='$0'
                      resize='none'
                      borderWidth='1px'
                      ml={4}
                    />
                  </Box>
                </>
              )}
            </Flex>
          </Box>
        )
      })}
    </>
  )
}
